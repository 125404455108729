@import "../../utils/custom-variables.scss";

.help-button {
  position: fixed;
  bottom: 4%;
  right: 4%;
  z-index: 999;
}

.round-btn {
  border-width: 2px !important;
  border-radius: 1.5rem !important;
}

.round-btn:hover {
  transform: translateY(-0.33em) !important;
}

@include media-breakpoint-down(lg) {
  .round-btn {
    border-width: 2px !important;
    border-radius: 1.5rem !important;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.messages-space {
  height: 55vh;
  width: 22rem;
}

.message {
  max-width: 75%;
  width: fit-content;
  padding: 5px 10px;
  min-width: 2rem;
  font-weight: 400;
  align-self: flex-end;
}

.user-message {
  background-color: #f7941e;
  color: #ffffff;
  border-radius: 10px 0px 10px 10px;
}

.agent-message {
  background-color: rgb(214, 214, 214);
  color: #000000;
  border-radius: 0px 10px 10px 10px;
}

.comment {
  display: flex;
  flex-direction: column;

  .time {
    color: #9a9a9a;
    font-size: 11px;
    margin-left: 3px;
    font-weight: 300;
    line-height: initial;
  }

  .user-space {
    display: flex;
    flex-direction: row-reverse;

    .user {
      text-align: right;
      margin: 0 5px 0 0;
    }

    .user-image {
      float: right;
      margin: 0 0 5px 5px;
    }
  }
  .agent-space {
    display: flex;
    flex-direction: row;
    .agent {
      text-align: left;
      margin: 0 0 0 5px;
    }
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.popover {
  border: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

@media (max-width: 768px) {
  .popover {
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
.help-chat-body {
  display: grid;
  align-items: center;
  /* Middle element occupies twice the space of the side elements */
  grid-template-rows: auto 1fr auto;
}
