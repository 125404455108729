@import "../../utils/custom-variables.scss";

.header-navbar {
  background-color: $white;
  
  .navbar-brand {
    img {
      width: 12rem;
      height: auto;
    }
  }

  .navbar-nav {
    .nav-item {
      color: $black;

      .nav-link {
        color: $black;
        font-size: 1rem;
        &:hover,
        &:active,
        &:focus,
        &.active {
          color: $orange;
        }

        &:not(.nav-btn) {
          margin-top: 0.6rem;
          margin-right: 1.5rem;
        }
      }

      .nav-btn {
        margin-top: 2px;
        .btn {
          font-size: 1.2rem;
          padding: 0.45rem 1rem;
          font-weight: bold;
        }
      }
    }
  }
}

.btn-tertiary {
  background-color: $white;
  border: 2px solid lighten($orange, 20%) !important;
  color: $black;

  &:hover,
  &:active {
    background-color: $white;
    color: $black;
    border: 2px solid lighten($orange, 20%);
  }
}

.navbar-underline {
  width: 100%;
  height: 5px;
  background-color: orange;
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 1rem;

        &:not(.nav-btn) {
          margin-right: 0.6rem;
        }
      }

      .nav-btn {
        padding-right: 0;

        .btn {
          font-size: 1rem;
          padding: 0.35rem 0.8rem;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .header-navbar {
    padding: 0.5rem 1rem;

    .navbar-brand {
      img {
        width: 10rem;
      }
    }
  }
}

.schedule-btn {
  background-color: #33c003 !important;
  border: none !important;

  &:hover {
    background-color: #6bca4c !important;
  }

  &:focus {
    box-shadow: unset !important;
  }

  &:hover {
    border: unset !important;
  }
}

.navbar-logo-container {
  width:170px;
}
