.sub-msg-ms {
  margin-left: 2.3rem;
}
.sub-msg-me {
  margin-right: 2.3rem;
}

.rm-box-shadow {
  box-shadow: none !important;
}
